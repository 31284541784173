import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./AFPM_Case_Study.module.css";
import CopyRight from "../../CopyRight/CopyRight";

const AFPM_Case_Study = ({ video, poster }) => {
  return (
    <>
      <section className={styles.wrapper}>
        <div className="container">
          <div className={styles.backToHomeAndText}>
            <div>
              <p className={styles.projectname}>
                Case Study
              </p>
              <h3 className={styles.projecttitle}>AFPM Social media Shorts</h3>
            </div>
            <Link to="/" style={{ textDecoration: 'none' }}>
                <div className="return-image-container">
                <img src="/images/mh_return.svg" alt="Return to Home" className="return-image" />
                </div>
                </Link>
          </div>

          <div className={styles.textContainer}>
            <p
              className={[styles.mhtext].join(" ")}
            >
              Over the last few years, I made a series of social media shorts for AFMP, an American trade association. These videos use kinetic texts, motion graphics, and animation to convey challenging concepts to industry professionals and policy makers, fostering increased awareness about their industry. These films are collaborations with JustAddFirewater.
            </p>



              <div className={styles.videoContainer}>
          <div className={styles.videoRow}>
            <iframe
              title="topVideo"
              src="https://iframe.mediadelivery.net/embed/14975/dbe6405d-ecec-40f7-875f-fc67b3b1ff8a?token=33f065267c8b1f1ccf463bedb2c4bde64ca5dbe97983f8dcc15fb18e8abd32d9&expires=1703591371&autoplay=false&loop=false&muted=false&preload=false&responsive=true"
              loading="lazy"
              allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
              allowFullScreen
            ></iframe>
          </div>
          <div className={styles.videoRow}>
          <div className={styles.rowContent}>
                <img
                  src="/images/casestudy.svg"
                  alt="Case Study"
                  className={styles.caseStudyImage}
                />
                <div className={styles.videoInfo}>
                  <h4>Lifeblood</h4>
                  <p className={styles.videoText}>
                  The heart, our industries, the progress of our modern world.</p>
                </div>
              </div>
                </div>
              </div>



              <div className={styles.videoContainer}>
          <div className={styles.videoRow}>
            <iframe
              title="topVideo"
              src="https://iframe.mediadelivery.net/embed/14975/ec109c36-0a27-4dee-84aa-c0f6caf87630?token=fe7cebacce1dd5eccf4969fcdd0b3d863e2b140b2d3ea8e547d00973d46ca9ae&expires=1703665217&autoplay=false&loop=false&muted=false&preload=false&responsive=true"
              loading="lazy"
              allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
              allowFullScreen
            ></iframe>
          </div>
          <div className={styles.videoRow}>
          <div className={styles.rowContent}>
                <img
                  src="/images/casestudy.svg"
                  alt="Case Study"
                  className={styles.caseStudyImage}
                />
                <div className={styles.videoInfo}>
                  <h4>Advanced Recyling</h4>
                  <p className={styles.videoText}>
                  Plastics embody stored value. Recent advancements in science unveil innovative techniques to break down plastics into their original polymer form, thus recapturing the inherent value that was once lost.</p>
                </div>
              </div>
                </div>
              </div>



              
              <div className={styles.videoContainer}>
          <div className={styles.videoRow}>
            <iframe
              title="topVideo"
              src="https://iframe.mediadelivery.net/embed/14975/9275f21a-ebe1-4779-924e-b6e950430fa2?token=e16185f79edf613084b84477424d317001a57066d298a9267ba6dd1ddc2bb1fd&expires=1703591420&autoplay=false&loop=false&muted=false&preload=false&responsive=true"
              loading="lazy"
              allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
              allowFullScreen
            ></iframe>
          </div>
          <div className={styles.videoRow}>
          <div className={styles.rowContent}>
                <img
                  src="/images/casestudy.svg"
                  alt="Case Study"
                  className={styles.caseStudyImage}
                />
                <div className={styles.videoInfo}>
                  <h4>HF Safety</h4>
                  <p className={styles.videoText}>
                  Hydrofluoric acid, a dangerous chemical, is needed for producing cleaner gasoline, requires careful handling. Fuel manufacturers implement many safety measures, specifically in the process of Alkylation, to ensure secure and responsible use.</p>
                </div>
              </div>
                </div>
              </div>
      
          </div>
        </div>
      </section>
      <CopyRight logo />
    </>
  );
};

export default AFPM_Case_Study;
