import Navbar from "./component/Navbar/Navbar";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Colead from "./component/Projects/Colead/Colead";
import MassiveTheory from "./component/Projects/Massive_Theory/Massive_Theory";
import PIGTV from "./component/Projects/PIG_TV/PIG_TV";
import TSICBD from "./component/Projects/TSICBD/TSICBD";
import ZolaInfinity from "./component/Projects/Zola_Infinity/Zola_Infinity";
import AFPMCaseStudy from "./component/Projects/AFPM_Case_Study/AFPM_Case_Study";
import WordOfLife from "./component/Projects/Word_Of_Life/Word_Of_Life";
import AtlasBrand from "./component/Projects/Atlas_Brand/Atlas_Brand";
import AtlasTrailer from "./component/Projects/Atlas_Trailer/Atlas_Trailer";

function App() {
  const data = [
    {
      poster: "/images/poster.jpg",
      title: "Atlas Shrugged Book Trailer",
      path: "/atlas_shrugged",
      video: "/videos/as_ai_thumb.mp4",
    },
    {
      poster: "/images/poster.jpg",
      path: "/word_of_life",
      video: "/videos/WOW.mp4",
      title: "Word Of Life",
    },
    {
      poster: "/images/poster.jpg",
      video: "/videos/AtlasShrugged_Gala.mp4",
      path: "/atlas_brand",
      title: "Atlas Brand Films",
    },
    {
      poster: "/images/poster.jpg",
      video: "/videos/MassiveTheory_Thumb2.mp4",
      path: "/massive_theory",
      title: "Massive Theory",
    },
    {
      poster: "/images/poster.jpg",
      video: "/videos/SocialVideo_CaseStudy.mp4",
      path: "/afpm_case_study",
      title: "AFPM Shorts",
    },
    {
      poster: "/images/poster.jpg",
      video: "/videos/ColeadThumb.mp4",
      path: "/colead",
      title: "Colead",
    },
    {
      poster: "/images/poster.jpg",
      video: "/videos/PigThumb_v5.mp4",
      path: "/pig_tv",
      title: "PIG TV",
    },

    {
      poster: "/images/poster.jpg",
      video: "/videos/TSICBD_Thumb.mp4",
      path: "/tsicbd",
      title: "TSICBD",
    },
    {
      poster: "/images/poster.jpg",
      video: "/videos/Zola_Thumb.mp4",
      path:"/zola_infinity",
      title:"Zola Infinity",
    },
  ];
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home data={data} />} />
        <Route
          path="/atlas_shrugged"
          element={
            <AtlasTrailer poster={data[8].poster} video={data[8].video} />
          }
        />
        <Route
          path="/word_of_life"
          element={
            <WordOfLife poster={data[8].poster} video={data[8].video} />
          }
        />
        <Route
          path="/atlas_brand"
          element={
            <AtlasBrand poster={data[8].poster} video={data[8].video} />
          }
        />
        <Route
          path="/massive_theory"
          element={
            <MassiveTheory poster={data[4].poster} video={data[4].video} />
          }
        />
        <Route
          path="/afpm_case_study"
          element={
            <AFPMCaseStudy poster={data[8].poster} video={data[8].video} />
          }
        />
        <Route
          path="/colead"
          element={
            <Colead poster={data[3].poster} video={data[3].video} />
          }
        />
        <Route
          path="/pig_tv"
          element={
            <PIGTV poster={data[5].poster} video={data[5].video} />
          }
        />
        <Route
          path="/tsicbd"
          element={
            <TSICBD poster={data[6].poster} video={data[6].video} />
          }
        />
        <Route
          path="/zola_infinity"
          element={
            <ZolaInfinity poster={data[7].poster} video={data[7].video} />
          }
        />
      </Routes>
    </>
  );
}

export default App;
