import React from "react";
import SocialContainer from "../Navbar/SocialContainer/SocialContainer";
import styles from "./Footer.module.css";
import ContactForm from "./ContactForm";


const Footer = () => {
  return (
    <section className="container">
      <div className={styles.wrapper}>
        <h3 className={styles.title}>Collaborate</h3>

        <p className={styles.footertext}>I've had the pleasure of collaborating with various companies, organizations, and brands. If you'd like to connect for a collaboration or say hi, feel free to reach out.</p>
      </div>
     <ContactForm />
      <div className={styles.socialWrapper}>
      </div>
    </section>
  );
};

export default Footer;
