import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./Atlas_Brand.module.css";
import CopyRight from "../../CopyRight/CopyRight";

const AtlasBrand = ({ video, poster }) => {
  return (
    <>
      <section className={styles.wrapper}>
        <div className="container">
          <div className={styles.backToHomeAndText}>
            <div>
              <p className={styles.projectname}>
                Case Study
              </p>
              <h3 className={styles.projecttitle}>Atlas Society Short</h3>
            </div>
            <Link to="/" style={{ textDecoration: 'none' }}>
                <div className="return-image-container">
                <img src="/images/mh_return.svg" alt="Return to Home" className="return-image" />
                </div>
                </Link>
          </div>

          <div className={styles.textContainer}>
            <p className={[styles.mhtext].join(" ")}>
            The ambitious goal of the Atlas Society is to captivate a billion young minds with the foundational ideas of Ayn Rand, serving as a compelling introduction to the concept of liberty. In collaboration with the organization, I crafted a series of animated and motion graphic pieces, offering companion visuals to effectively convey the ideals of reason, achievement, and freedom.
            </p>
            
            
            <div className={styles.videoContainer}>
          <div className={styles.videoRow}>
            <iframe
              title="topVideo"
              src="https://iframe.mediadelivery.net/embed/14975/fa631b5f-66f3-4292-b6af-9c752ae5bfff?token=04814e2639374730c39106bfd5c97dc2e3414c4335972311e774d9511b9f917b&expires=1703589380&autoplay=false&loop=false&muted=false&preload=false&responsive=true"
              loading="lazy"
              allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
              allowFullScreen
            ></iframe>
          </div>
          <div className={styles.videoRow}>
          <div className={styles.rowContent}>
                <img
                  src="/images/casestudy.svg"
                  alt="Case Study"
                  className={styles.caseStudyImage}
                />
                <div className={styles.videoInfo}>
                  <h4>Atlas Society Brand Collage</h4>
                  <p className={styles.videoText}>
                  This is a short film featuring a mix of 3d animation and motion graphics montage. Narrated by CEO Jennifer Grossman. Featuring 3d model of Atlas by <a href="https://sketchfab.com/Wnight" target="_blank" rel="noreferrer" className={styles.watchLink}>Max Wnight</a>.</p>
                </div>
              </div>
                </div>
              </div>


              <div className={styles.videoContainer}>
          <div className={styles.videoRow}>
            <iframe
              title="topVideo"
              src="https://iframe.mediadelivery.net/embed/14975/bdc1896a-f928-48b5-85d3-19f3b247bbe3?token=a59c67393105b35c36bebe6b5383a456ad0eadfce695a2b94bed2a76b1841451&expires=1703591728&autoplay=false&loop=false&muted=false&preload=false&responsive=true"
              loading="lazy"
              allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
              allowFullScreen
            ></iframe>
          </div>
          <div className={styles.videoRow}>
          <div className={styles.rowContent}>
                <img
                  src="/images/casestudy.svg"
                  alt="Case Study"
                  className={styles.caseStudyImage}
                />
                <div className={styles.videoInfo}>
                  <h4>The Flying Tesla</h4>
                  <p className={styles.videoText}>
                  A series of animation sequences created as companion pieces for a an event.</p>
                </div>
              </div>
                </div>
              </div>



            


          </div>
        </div>
      </section>
      <CopyRight logo />
    </>
  );
};

export default AtlasBrand;
