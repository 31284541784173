import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./TSICBD.module.css";
import CopyRight from "../../CopyRight/CopyRight";

const TSICBD= ({ video, poster }) => {
  const [iframeSrc] = useState(
    "https://iframe.mediadelivery.net/embed/14975/7fd5b278-4bc8-47df-a54a-3bc0ef3f3298?token=05e0d93b70c3b3cc96f795433ffde365266979a1d5c0884c040672a452b3a2a7&expires=1703581006&autoplay=false&loop=false&muted=false&preload=false&responsive=true"
  );
  return (
    <>
      <section className={styles.wrapper}>
        <div className="container">
          <div className={styles.backToHomeAndText}>
            <div>
              <p className={styles.projectname}>
                Feature Film
              </p>
              <h3 className={styles.projecttitle}>They Say It Can't Be Done</h3>
            </div>
            <Link to="/" style={{ textDecoration: 'none' }}>
                <div className="return-image-container">
                <img src="/images/mh_return.svg" alt="Return to Home" className="return-image" />
                </div>
                </Link>
          </div>

          <div className={styles.videoContainer}>
            <div style={{ position: "relative", paddingTop: "56.25%" }}>
              <iframe
                title="video"
                src={iframeSrc}
                loading="lazy"
                style={{ border: 0, position: "absolute", top: 0, height: "100%", width: "100%" }}
                allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
                allowFullScreen
              ></iframe>
            </div>
          </div>

          <div className={styles.textContainer}>
            <p
              className={[styles.mhtext].join(" ")}
            >
               THEY SAY IT CAN’T BE DONE is a documentary that explores how innovation can solve some of the world’s toughest problems. The film follows the stories of four companies on the brink of disruptive innovation, but all have the common roadblock of regulations that threaten to limit their success. I designed the interior of the Regulatory Apparatus, a spaceship, and an altar of technology.<br /><br />
              <a href="https://www.theysayitcantbedone.com/trailer" target="_blank" rel="noreferrer" className={styles.watchLink}>
                Watch the Trailer Here
              </a><br /><br />
              This is a collaboration with 
              <a href="https://www.justaddfirewater.com" target="_blank" rel="noreferrer" className={styles.watchLink}> JustAddFirewater
              </a>
            </p>
          </div>
        </div>
      </section>
      <CopyRight logo />
    </>
  );
};

export default TSICBD;
