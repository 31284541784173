import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./Zola_Infinity.module.css";
import CopyRight from "../../CopyRight/CopyRight";

const ZolaInfinity= ({ video, poster }) => {
  const [iframeSrc] = useState(
    "https://iframe.mediadelivery.net/embed/14975/12947f93-85d4-4691-8299-151136f64cd4?token=de23e3901f4f3e4d409228942e9057cd7952aadb258669c11d701a04059fc0fe&expires=1703581093&autoplay=false&loop=false&muted=false&preload=false&responsive=true"
  );
  return (
    <>
      <section className={styles.wrapper}>
        <div className="container">
          <div className={styles.backToHomeAndText}>
            <div>
              <p className={styles.projectname}>
                Commercial
              </p>
              <h3 className={styles.projecttitle}>Zola Infinity</h3>
            </div>
            <Link to="/" style={{ textDecoration: 'none' }}>
                <div className="return-image-container">
                <img src="/images/mh_return.svg" alt="Return to Home" className="return-image" />
                </div>
                </Link>
          </div>

          <div className={styles.videoContainer}>
            <div style={{ position: "relative", paddingTop: "56.25%" }}>
              <iframe
                title="video"
                src={iframeSrc}
                loading="lazy"
                style={{ border: 0, position: "absolute", top: 0, height: "100%", width: "100%" }}
                allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
                allowFullScreen
              ></iframe>
            </div>
          </div>

          <div className={styles.textContainer}>
            <p
              className={[styles.mhtext].join(" ")}
            >
               ZOLA INFINITY is an Integrated Power System (IPS) that incorporates solar power, energy storage, and power from the grid to deliver reliable 24-HOUR POWER anywhere at the lowest possible cost. INFINITY delivers kilowatts of clean AC power, scalable to any customer demand.
             <br/><br/>
             This is a collaboration with <a href="https://finalproduct.net/" target="_blank" rel="noreferrer" className={styles.watchLink}>
               Final Product</a>.
            </p>
          </div>
        </div>
      </section>
      <CopyRight logo />
    </>
  );
};

export default ZolaInfinity;
