import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./Atlas_Trailer.module.css";
import CopyRight from "../../CopyRight/CopyRight";

const AtlasTrailer = ({ video, poster }) => {
  const [iframeSrc] = useState(
    "https://www.youtube.com/embed/NUn6JJsJroA?si=6rotAOEJFi80-1kr"
  );
  return (
    <>
      <section className={styles.wrapper}>
        <div className="container">
          <div className={styles.backToHomeAndText}>
            <div>
              <p className={styles.projectname}>
                Project Name
              </p>
              <h3 className={styles.projecttitle}>Atlas Shrugged Trailer</h3>
            </div>
                <Link to="/" style={{ textDecoration: 'none' }}>
                <div className="return-image-container">
                <img src="/images/mh_return.svg" alt="Return to Home" className="return-image" />
                </div>
                </Link>
          </div>

          <div className={styles.videoContainer}>
            <div style={{ position: "relative", paddingTop: "56.25%" }}>
              <iframe
                title="video"
                src={iframeSrc}
                loading="lazy"
                style={{ border: 0, position: "absolute", top: 0, height: "100%", width: "100%" }}
                allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
                allowFullScreen
              ></iframe>
            </div>
          </div>

          <div className={styles.textContainer}>
            <p
              className={[styles.mhtext].join(" ")}
            >
              Experience the intersection of Artificial Intelligence and Ayn Rand's 'Atlas Shrugged' in an innovative book trailer. This collaborative project, in partnership with <a href="https://www.atlassociety.org" target="_blank" rel="noreferrer" className={styles.watchLink}>Atlas Society</a> and <a href="https://justaddfirewater.com" target="_blank" rel="noreferrer" className={styles.watchLink}>JustAddFirewater</a>, integrates real-life performance capture with AI-driven 2D art and 3D animation. This experimental book trailer seeks to present Rand's famous work in a new light, blending the power of AI with the enduring narrative of 'Atlas Shrugged. </p>
              
            <p className={[styles.mhtext].join(" ")}>Director: Victoria Hill</p>
            <p className={[styles.mhtext].join(" ")}>AI Artist: <a href="https://www.youtube.com/@831digital" rel="noreferrer" target="_blank" className={styles.watchLink}>Rob Capili</a></p>
            <p className={[styles.mhtext].join(" ")}>Contributing 3D Artist: <a href="https://sketchfab.com/Wnight" rel="noreferrer" target="_blank" className={styles.watchLink}>Wnight</a></p>
                    

          </div>
        </div>
      </section>
      <CopyRight logo />
    </>
  );
};

export default AtlasTrailer;
