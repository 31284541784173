import React from "react";
import styles from "./CopyRight.module.css";
const CopyRight = ({ logo }) => {
  const currentYear = new Date().getFullYear();
  return (
    <div className={styles.wrapper}>
      {logo && <img className={styles.logo} src="/images/logo.svg" alt="#" />}
      <p className={styles.text}>{`${currentYear} MotionHaus.com All Rights Reserved`}</p>
    </div>
  );
};

export default CopyRight;
