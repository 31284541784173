import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./Colead.module.css";
import CopyRight from "../../CopyRight/CopyRight";

const Colead = ({ video, poster }) => {
  const [iframeSrc] = useState(
    "https://iframe.mediadelivery.net/embed/14975/8a5288f0-871b-4d52-851d-f774f53f862e?token=e12684d808962003d66ff28df35e689ebff683c82739a9b0959e775e06b9915f&expires=1703580729&autoplay=false&loop=false&muted=false&preload=false&responsive=true"
  );
  return (
    <>
      <section className={styles.wrapper}>
        <div className="container">
          <div className={styles.backToHomeAndText}>
            <div>
              <p className={styles.projectname}>
                Project Name
              </p>
              <h3 className={styles.projecttitle}>COLEAD</h3>
            </div>
            <Link to="/" style={{ textDecoration: 'none' }}>
                <div className="return-image-container">
                <img src="/images/mh_return.svg" alt="Return to Home" className="return-image" />
                </div>
                </Link>
          </div>

          <div className={styles.videoContainer}>
            <div style={{ position: "relative", paddingTop: "56.25%" }}>
              <iframe
                title="video"
                src={iframeSrc}
                loading="lazy"
                style={{ border: 0, position: "absolute", top: 0, height: "100%", width: "100%" }}
                allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
                allowFullScreen
              ></iframe>
            </div>
          </div>

          <div className={styles.textContainer}>
            <p
              className={[styles.mhtext].join(" ")}
            >
             Thousands of people in Seattle struggle everyday with substance use, mental illness, and income insecurity. And the old approach – jail and prosecution, just isn’t working. In the past year, encampments have become the new jails and we have abandoned our most vulnerable. Colead program offers real change.
             <br/><br/>
             This is a collaboration with <a href="https://finalproduct.net/" rel="noreferrer" target="_blank" className={styles.watchLink}>
               Final Product</a>.
            </p>
          </div>
        </div>
      </section>
      <CopyRight logo />
    </>
  );
};

export default Colead;
