import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./PIG_TV.module.css";
import CopyRight from "../../CopyRight/CopyRight";

const PigTv= ({ video, poster }) => {
  const [iframeSrc] = useState(
    "https://iframe.mediadelivery.net/embed/14975/ce8f0691-71fe-41ea-b6d4-f9ebe6fb093b?token=8531348c63271784cfa59f9a05be491c3d60f0059e46fd2c5a2c4cec1070b8fa&expires=1703580954&autoplay=false&loop=false&muted=false&preload=false&responsive=true"
  );
  return (
    <>
      <section className={styles.wrapper}>
        <div className="container">
          <div className={styles.backToHomeAndText}>
            <div>
              <p className={styles.projectname}>
                Animated Web Series
              </p>
              <h3 className={styles.projecttitle}>PIGT TV</h3>
            </div>
            <Link to="/" style={{ textDecoration: 'none' }}>
                <div className="return-image-container">
                <img src="/images/mh_return.svg" alt="Return to Home" className="return-image" />
                </div>
                </Link>
          </div>

          <div className={styles.videoContainer}>
            <div style={{ position: "relative", paddingTop: "56.25%" }}>
              <iframe
                title="video"
                src={iframeSrc}
                loading="lazy"
                style={{ border: 0, position: "absolute", top: 0, height: "100%", width: "100%" }}
                allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
                allowFullScreen
              ></iframe>
            </div>
          </div>

          <div className={styles.textContainer}>
            <p
              className={[styles.mhtext].join(" ")}
            >
              Starring Tom Woods & Michael Malice, and based on the Regnery Publishing book series of the same name, “The Politically Incorrect Guide” covers the undiscussed facts and stories about history, culture, and social movements, purged from today’s mainstream education system. Tom Woods penned the very first book in the series, “The Politically Incorrect Guide to American History”, which was a New York Times bestseller. This is a collaboration with 
              <a href="https://www.justaddfirewater.com" target="_blank" rel="noreferrer" className={styles.watchLink}> JustAddFirewater.</a></p>
              <p className={[styles.mhtext].join(" ")}>
              <a href="https://www.youtube.com/playlist?list=PLqcwVcVuLX4ddJF00YL4a1fpHlzkV8KWH" target="_blank" rel="noreferrer" className={styles.watchLink}>
                Watch the Series on Youtube
              </a></p>
            
          </div>
        </div>
      </section>
      <CopyRight logo />
    </>
  );
};

export default PigTv;
