import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./Word_Of_Life.module.css";
import CopyRight from "../../CopyRight/CopyRight";

const WordOfLife = ({ video, poster }) => {
  const [iframeSrc] = useState(
    "https://iframe.mediadelivery.net/embed/14975/d38b7fd0-dfb1-41cb-9d29-d6c63b87eeac?autoplay=false&loop=false&muted=false&preload=false&responsive=true"
  );
  return (
    <>
      <section className={styles.wrapper}>
        <div className="container">
          <div className={styles.backToHomeAndText}>
            <div>
              <p className={styles.projectname} color="#ac9555">
                Project Name
              </p>
              <h3 className={styles.projecttitle}>Word of Life</h3>
            </div>
            <Link to="/" style={{ textDecoration: 'none' }}>
                <div className="return-image-container">
                <img src="/images/mh_return.svg" alt="Return to Home" className="return-image" />
                </div>
                </Link>
          </div>

          <div className={styles.videoContainer}>
            <div style={{ position: "relative", paddingTop: "56.25%" }}>
              <iframe
                title="video"
                src={iframeSrc}
                loading="lazy"
                style={{ border: 0, position: "absolute", top: 0, height: "100%", width: "100%" }}
                allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
                allowFullScreen
              ></iframe>
            </div>
          </div>

          <div className={styles.textContainer}>
            <p
              className={[styles.mhtext].join(" ")}
            >
              Word of Life is a live action web series with stylized vector style animated sequences. MotionHaus provided animation for the companion 2d sequences. This is a collaboration with <a href="https://www.coronationmedia.com/" target="_blank" rel="noreferrer" className={styles.watchLink}>
               Coronation Media</a>.
            </p>
          </div>
        </div>
      </section>
      <div style={{ marginTop: "auto" }}>
      <CopyRight logo />
      </div>
    </>
  );
};

export default WordOfLife;
