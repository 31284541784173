// ContactForm.js
import React from 'react';
import { useForm, ValidationError } from '@formspree/react';

function ContactForm() {
    const [state, handleSubmit] = useForm("mvoeplra");
    if (state.succeeded) {
        return <p>Thanks for the message!</p>;
    }
    return (
        <form onSubmit={handleSubmit}>
        <label for="name">
        Your Name
        </label>
        <input
          id="name"
          type="name" 
          name="name"
        />
        <ValidationError 
          prefix="Name" 
          field="name"
          errors={state.errors}
        />
        <br />
        <label htmlFor="email">
          Email Address
        </label>
        <input
          id="email"
          type="email" 
          name="email"
        />
        <ValidationError 
          prefix="Email" 
          field="email"
          errors={state.errors}
        />
        <br />
        <label htmlFor="company">
          Your Company
        </label>
        <input
          id="company"
          type="company" 
          name="company"
        />
         <br />
         <label for="message">
          Tell me about your project:
        </label>
        <textarea
          id="message"
          name="message"
        />
        <ValidationError 
          prefix="Message" 
          field="message"
          errors={state.errors}
        />
        <br />
        <button type="submit" disabled={state.submitting}>
          Send
        </button>
      </form>
    );
  }

export default ContactForm;