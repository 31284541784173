import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./Massive_Theory.module.css";
import CopyRight from "../../CopyRight/CopyRight";

const MassiveTheory = ({ video, poster }) => {

  return (
    <>
      <section className={styles.wrapper}>
        <div className="container">
          <div className={styles.backToHomeAndText}>
            <div>
              <p className={styles.projectname}>
                Creative Showcase
              </p>
              <h3 className={styles.projecttitle}>Massive Theory</h3>
            </div>
            <Link to="/" style={{ textDecoration: 'none' }}>
                <div className="return-image-container">
                <img src="/images/mh_return.svg" alt="Return to Home" className="return-image" />
                </div>
                </Link>
          </div>

          <div className={styles.textContainer}>
            <p
              className={[styles.mhtext].join(" ")}
            >
              Massive Theory is a YouTube showcase channel featuring animated content about science, technology, and the future of humanity, all interwoven with a touch of spirituality in this uniquely personal space.
            </p>


            <div className={styles.videoContainer}>
          <div className={styles.videoRow}>
            <iframe
              title="topVideo"
              src="https://www.youtube.com/embed/_2byqgXv4dE"
              loading="lazy"
              allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
              allowFullScreen
            ></iframe>
          </div>
          <div className={styles.videoRow}>
          <div className={styles.rowContent}>
                <img
                  src="/images/casestudy.svg"
                  alt="Case Study"
                  className={styles.caseStudyImage}
                />
                <div className={styles.videoInfo}>
                  <h4>Can we create conscious AI?</h4>
                  <p className={styles.videoText}>
                   Can we ever create conscious Artificial Intelligence? Would it be for the benefit of humanity, or are we "summoning the demon" like Elon Musk said? </p>
                </div>
              </div>
                </div>
              </div>


              <div className={styles.videoContainer}>
          <div className={styles.videoRow}>
            <iframe
              title="topVideo"
              src="https://www.youtube.com/embed/N4ihTgh9Y2Y"
              loading="lazy"
              allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
              allowFullScreen
            ></iframe>
          </div>
          <div className={styles.videoRow}>
          <div className={styles.rowContent}>
                <img
                  src="/images/casestudy.svg"
                  alt="Case Study"
                  className={styles.caseStudyImage}
                />
                <div className={styles.videoInfo}>
                  <h4>Dark Forest Theory the Prime Directive</h4>
                  <p className={styles.videoText}>
                  The Universe is vast and should be teeming with life. Yet despite scientists' efforts to monitor for and occasionally signal to extraterrestrials, we have not found any evidence that aliens exist. This conundrum has come to be known as the Fermi Paradox.</p>
                </div>
              </div>
                </div>
              </div>

              
              <div className={styles.videoContainer}>
          <div className={styles.videoRow}>
            <iframe
              title="topVideo"
              src="https://www.youtube.com/embed/VkJuiXyUDmk"
              loading="lazy"
              allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
              allowFullScreen
            ></iframe>
          </div>
          <div className={styles.videoRow}>
          <div className={styles.rowContent}>
                <img
                  src="/images/casestudy.svg"
                  alt="Case Study"
                  className={styles.caseStudyImage}
                />
                <div className={styles.videoInfo}>
                  <h4>The Gateway Experience</h4>
                  <p className={styles.videoText}>
                  During the Cold War, the spy agency CIA did secret investigations into the "Gateway Experience", a training system designed to transcend space time. How is this possible? Let's take a look.</p>
                </div>
              </div>
                </div>
              </div>




          </div>
        </div>
      </section>
      <CopyRight logo />
    </>
  );
};

export default MassiveTheory;
