import React from "react";
import styles from "./HeadingAndInfo.module.css";

const HeadingAndInfo = () => {
  return (
    <section className="container">
      <div className={styles.wrapper}>
        <h3 className={styles.title}>HI, I'M JAROD!</h3>
        <p className={styles.headlinetext}>
          LA based filmmaker crafting compelling narratives through
          <span className={styles.highlightedText}> motion graphics</span>,
          <span className={styles.highlightedText}> VFX</span>, and
          <span className={styles.highlightedText}> animation</span>.
          Let's create together!
        </p>
      </div>
    </section>
  );
};

export default HeadingAndInfo;
